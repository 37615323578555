@import "bootstrap/bootstrap";
@import "//fonts.googleapis.com/css?family=Roboto:400,300";

@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
  font-weight: 300;
}

nav {
	@extend .font-weight-normal
}

#homeHeadingSubtitle {
	@extend .font-weight-normal
}

.main-container
{
	margin-top: 50px;
	margin-bottom: 50px;
}

.navbar
{
	border-radius: 0px;
}

.nowrap
{
	white-space: nowrap;
}

.cover-photo {
	margin-bottom: 20px;
	margin-top: 20px;
}